import { isDRAWPlusSignedUser } from "../app_constants";

export const DRAWPlusAppLink = () => {
  if (!isDRAWPlusSignedUser) {
    return null;
  }
  return (
    <a
      href={`${process.env.REACT_APP_PLUS_APP}?utm_source=DRAW&utm_medium=app&utm_content=signedInUserRedirectButton#DRAW-redirect`}
      target="_blank"
      rel="noreferrer"
      className="plus-button"
    >
      Go to DRAW+
    </a>
  );
};
